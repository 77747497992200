import { useState, useEffect, useRef } from "react";
// import { useParams } from "react-router-dom";

import classNames from "classnames";
import {
	Column,
	Toast,
	Button,
	Dialog,
	InputText,
	Dropdown,
	InputTextarea,
	TabView,
	TabPanel,
	tabHeaderIIespanol,
	tabHeaderIIingles,
	EditorHtml,
	Calendar,
	ListBox,
	Avatar,
	AvatarGroup,
	addLocale,
	locale,
	Tag,
	Card,
} from "../../components/crud";
import { Conexion } from "../../service/Conexion";
import {
	TablaDatos,
	BarraSuperior,
	EliminarVentana,
	productDialogFooter,
} from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { setDataSet, setFormData } from "../../store/appSlice";
import { Cargando } from "../../components/crud/Cargando";
// import { ImagenCampo } from "../../components/crud/ImagenCampo";

export const Citas = () => {
	const TABLA = "agendamiento";
	let emptyFormData = {};
	const { dataSet, formData } = useSelector((state) => state.appsesion); //datos el storage redux
	const dispatch = useDispatch();
	const [productDialog, setProductDialog] = useState(false);
	const [deleteProductDialog, setDeleteProductDialog] = useState(false);
	const [submitted, setSubmitted] = useState(false);
	const toast = useRef(null);
	const [recargar, setrecargar] = useState(0);
	const [cargando, setCargando] = useState(false);
	const datatable = new Conexion();
	const [valueDropTipoEventos, setvalueDropTipoEventos] = useState(null);
	const [valueDropUbicacion, setvalueDropUbicacion] = useState(null);
	const [valueDropUbicacionfilter, setvalueDropUbicacionfilter] = useState(
		null
	);
	const [valueDropLugares, setdropdowlugares] = useState(null);
	const [valueDropHora, setvalueDropHora] = useState(null);
	const [valueDropMinuto, setvalueDropMinuto] = useState(null);
	const [valueOpcionsacreditados, setvalueOpcionsacreditados] = useState(
		null
	);
	const [valueOpcionsaAgendados, setvalueOpcionsaAgendados] = useState([]);

	const [validHora, setvalidHora] = useState(false);
	const [selectedAcreditados, setselectedAcreditados] = useState(null);
	const [lugarDisponible, setlugarDisponible] = useState(true);
	const [personasAgenda, setpersonasAgenda] = useState([]);
	const [cardseleccionados, setcardseleccionados] = useState([]);
	const [cardconsulta, setcardconsulta] = useState([]);

	addLocale("es", {
		firstDayOfWeek: 1,
		dayNames: [
			"domingo",
			"lunes",
			"martes",
			"miércoles",
			"jueves",
			"viernes",
			"sábado",
		],
		dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
		dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
		monthNames: [
			"enero",
			"febrero",
			"marzo",
			"abril",
			"mayo",
			"junio",
			"julio",
			"agosto",
			"septiembre",
			"octubre",
			"noviembre",
			"diciembre",
		],
		monthNamesShort: [
			"ene",
			"feb",
			"mar",
			"abr",
			"may",
			"jun",
			"jul",
			"ago",
			"sep",
			"oct",
			"nov",
			"dic",
		],
		today: "Hoy",
		clear: "Limpiar",
	});

	locale("es");

	const AddCard = (card) => {
		// console.log({ card });
		let repetido = false;
		cardseleccionados.forEach((element) => {
			// console.log( element?.id );
			if (element?.id === card?.id) repetido = true;
		});
		if (!repetido) {
			setcardseleccionados([...cardseleccionados, card]);
			setcardconsulta(card);
		}
		// console.log(cardseleccionados);
	};

	const DeleteCard = (card) => {
		//borra el card seleccionado
		let cardseleccionadosfiltrado = cardseleccionados.filter((element) => {
			if (element?.id !== card?.id) {
				return element;
			}
		});
		setcardseleccionados(cardseleccionadosfiltrado);
		setcardconsulta([]);
	};

	const countryTemplate = (option) => {
		return (
			<div className='card product-item'>
				<div className='image-container'>
					{/* <img src={option.fotoacreditacion} alt={option.id} /> */}
					<Avatar
						image={option.fotoacreditacion}
						className='mr-2'
						size='xlarge'
						shape='circle'
					/>
				</div>
				<div className='product-list-detail'>
					<h5 className='mb-1'>{option.nombre} </h5>
					{option.nombreempresa && (
						<h6 className='mb-1'>
							Empresa / Proyecto: <br></br> {option.nombreempresa}
						</h6>
					)}
					{option.cargoempresa && (
						<h6 className='mb-1'>
							Cargo: <br></br> {option.cargoempresa}
						</h6>
					)}
					<div className='product-list-action'>
						<Button
							className='mt-3 '
							onClick={() => {
								AddCard(option);
							}}
							icon='pi pi-arrow-right'
						/>
					</div>
				</div>
			</div>
		);
	};

	const InvitadoTemplate = (option) => {
		// console.log(option);
		return (
			<div className='card product-item'>
				<div className='image-container'>
					{/* <img src={option.fotoacreditacion} alt={option.id} /> */}
					<Avatar
						image={option.fotoacreditacion}
						className='mr-2'
						size='xlarge'
						shape='circle'
					/>
				</div>
				<div className='product-list-detail'>
					<h5 className='mb-1'>{option.nombre} </h5>
					{option.nombreempresa && (
						<h6 className='mb-1'>
							Empresa / Proyecto: <br></br> {option.nombreempresa}
						</h6>
					)}
					{option.cargoempresa && (
						<h6 className='mb-1'>
							Cargo: <br></br> {option.cargoempresa}
						</h6>
					)}
					<div className='product-list-action'>
						<Button
							className='mt-3  p-button-danger'
							onClick={() => {
								DeleteCard(option);
							}}
							icon='pi pi-trash'
						/>
					</div>
				</div>
			</div>
		);
	};

	// console.log({grupo});

	useEffect(() => {
		//cargar la data total
		setCargando(true);
		dispatch(setFormData(emptyFormData));

		datatable.gettable(TABLA).then((data) => {
			dispatch(setDataSet(data));
			setCargando(false);
			// console.log({data});
		});
		setcardseleccionados([]);
	}, [recargar]);

	useEffect(() => {
		datatable
			.gettable("parametros/agendatipo")
			.then((menu) => setvalueDropTipoEventos(menu));

		datatable
			.gettable("parametros/agendaubicacion")
			.then((menu) => setvalueDropUbicacion(menu));

		datatable.gettable("parametros/lugares").then((datos) => {
			setdropdowlugares(datos);
		});

		datatable.gettable("parametros/parametros/horasdia").then((datos) => {
			setvalueDropHora(datos);
		});

		datatable.gettable("parametros/parametros/minutosdia").then((datos) => {
			setvalueDropMinuto(datos);
		});

		datatable.gettable("parametros/acreditadosagenda").then((datos) => {
			setvalueOpcionsacreditados(datos);
		});
	}, []);

	useEffect(() => {
		//efecto para verfica si la hora de inicio es menor a la hora fin
		if (formData.hor_ini_age && formData.hor_fin_age) {
			// console.log('solo horas');
			let horaini = parseInt(formData.hor_ini_age);
			let horafin = parseInt(formData.hor_fin_age);
			if (horaini > horafin) {
				toast.current.show({
					severity: "error",
					summary: "Error",
					detail: "La hora de inicio debe ser menor a la hora fin",
					life: 4000,
				});
				return;
			}
		}
		// console.log(formData);
		if (
			formData.hor_fin_age &&
			formData.hor_ini_age &&
			formData.min_ini_age &&
			formData.min_fin_age &&
			formData.fec_age
		) {
			let horaini = parseInt(formData.hor_ini_age);
			let minini = parseInt(formData.min_ini_age);
			let horafin = parseInt(formData.hor_fin_age);
			let minfin = parseInt(formData.min_fin_age);
			// debugger
			if (horaini >= horafin && minini >= minfin) {
				// console.log('entro por todas las horas');
				toast.current.show({
					severity: "error",
					summary: "Error",
					detail:
						"La hora y minuto de inicio debe ser menor a la hora fin",
					life: 8000,
				});
				setvalidHora(true);
				return;
			} else {
				setvalidHora(false);
			}
		}
	}, [formData]);

	useEffect(() => {
		// console.log('si hay oparticipanes',{formData} );
		if (formData.participantes) {
			// console.log('hay algun participante');
			let dataselecionadosinicial = [];
			formData.participantes.forEach((element) => {
				// console.log(element);
				dataselecionadosinicial.push(element);
				// setcardseleccionados([cardseleccionados, element])

				// 		setcardseleccionados([...cardseleccionados, element]);
				// 		// AddCard(element);
			});
			// console.log(dataselecionadosinicial);
			setcardseleccionados(dataselecionadosinicial);
		}
	}, [formData?.participantes]);

	//efecto  verificar disponibilidad persona
	useEffect(() => {
		console.log("se agrego un card");
		console.log({ cardconsulta });
		// console.log({cardseleccionados});
		// debugger
		if (cardconsulta?.id && formData.cod_lug_age && formData.cod_ubi_age) {
			console.log("si hay q consultars");
			let datacunsulta = { ...formData, idacreditado: cardconsulta.id };
			datatable
				.getvalidacion(
					"parametros/personadisponiblevalidacion",
					datacunsulta
				)
				.then((data) => {
					console.log(data);
					if (data?.disponible != 0) {
						DeleteCard(cardconsulta);
						toast.current.show({
							severity: "error",
							// summary: "Error",
							// detail: "Persona no Disponible",
							detail: mostrarerror(data, "Persona No Disponible"),
							life: 99000,
						});
						setlugarDisponible(false);
					} else {
						// console.log("si disponible");
						setlugarDisponible(true);
					}
				});
		}
	}, [cardconsulta]);

	useEffect(() => {
		// console.log(formData);

		if (formData.cod_lug_age) {
			let lugar = formData.cod_lug_age;
			let ubicaciones = valueDropUbicacion.filter(
				(x) => x.cod_lug_ubi === lugar
			);
			setvalueDropUbicacionfilter(ubicaciones);
		}

		if (formData.cod_lug_age && formData.cod_ubi_age) {
			datatable
				.getvalidacion("parametros/agendaubicacionvalidacion", formData)
				.then((data) => {
					console.log(data, "validacion");
					if (data?.disponible != 0) {
						setFormData({
							...formData,
							cod_ubi_age: null,
						});
						dispatch(
							setFormData({
								...formData,
								cod_ubi_age: null,
							})
						);
						// console.log("no disponible");
						toast.current.show({
							severity: "error",
							// summary: "No Disponible",
							// sticky: true,
							detail: mostrarerror(data, "Lugar No Disponible"),

							life: 99000,
						});
						setlugarDisponible(false);
					} else {
						// console.log("si disponible");
						setlugarDisponible(true);
					}
				});
		}
	}, [formData.cod_lug_age, formData.cod_ubi_age]);

	const mostrarerror = ({ citas }, titulo) => {
		return (
			<div className='toastBody'>
				<p>{titulo}</p>
				<ul>
					{citas.map((cita, index) => (
						<li key={index}>
							Cita: <strong>{cita.codigo}</strong>, {cita.nombre}
						</li>
					))}
				</ul>
			</div>
		);
	};

	useEffect(() => {
		let data = [];
		// console.log("algo cambio en cardseleccionados");
		cardseleccionados.forEach((element) => {
			// console.log(element?.id);
			data.push(element?.id);
		});
		// console.log(data);
		dispatch(
			setFormData({
				...formData,
				listado: data.toString(),
			})
		);
	}, [cardseleccionados]);

	/*eventos*/
	const openNew = () => {
		dispatch(setFormData(emptyFormData));
		setSubmitted(false);
		setProductDialog(true);
		setcardseleccionados([]);
	};
	const hideDialog = () => {
		setSubmitted(false);
		setProductDialog(false);
	};

	const hideDeleteProductDialog = () => {
		setDeleteProductDialog(false);
	};

	const editProduct = (id) => {
		setCargando(true);
		datatable
			.getItem(TABLA, id)
			.then((data) => dispatch(setFormData({ ...data.data })));
		setProductDialog(true);
		setCargando(false);
	};

	const confirmDeleteProduct = (fila) => {
		dispatch(setFormData(fila));
		setDeleteProductDialog(true);
	};
	const trasaccionExitosa = (tipo = "") => {
		setrecargar(recargar + 1);
		tipo === "borrar" ? setDeleteProductDialog(false) : hideDialog();
		dispatch(setFormData(emptyFormData));

		toast.current.show({
			severity: "success",
			summary: "Confirmacion",
			detail: "Transacción Exitosa",
			life: 4000,
		});
	};
	const cambiohtml = (x, name) => {
		let _product = { ...formData };
		_product[`${name}`] = x;
		dispatch(setFormData(_product));
	};

	/*eventos*/

	/**operacion transacciones */
	const saveProduct = () => {
		setSubmitted(true);
		if (formData.fec_age?.trim()) {
			// console.log(formData);
			// debugger
			setCargando(true);
			if (formData.id == null) {
				//nuevo registro
				datatable
					.getCrearItem(TABLA, formData)
					.then((data) => trasaccionExitosa());
			} else {
				//editar registro
				datatable
					.getEditarItem(TABLA, formData, formData.id)
					.then((data) => trasaccionExitosa());
			}
		}
	};
	const deleteProduct = () =>
		datatable
			.getEliminarItem(TABLA, formData, formData.id)
			.then((data) => trasaccionExitosa("borrar"));
	/**operacion transacciones */

	/* validaciones de campos */
	const onInputChange = (e, name) => {
		// console.log(e.target, e.target.value, name);
		const val = (e.target && e.target.value) || "";
		let _product = { ...formData };
		_product[`${name}`] = val;
		// console.log(_product);
		dispatch(setFormData(_product));
	};

	const onInputChangeDate = (e, name) => {
		const val = e.value.toISOString().split("T")[0] || "";
		let _product = { ...formData };
		_product[`${name}`] = val;
		dispatch(setFormData(_product));
	};

	const actionBodyTemplate = (rowData) => {
		return (
			<div
				className='actions'
				style={{
					display: "flex",
				}}>
				<Button
					icon='pi pi-pencil'
					className='p-button-rounded p-button-success mr-2'
					onClick={() => editProduct(rowData.id)}
				/>
				<Button
					icon='pi pi-trash'
					className='p-button-rounded p-button-warning mr-2'
					onClick={() => confirmDeleteProduct(rowData)}
				/>
			</div>
		);
	};

	const avatarBodyTemplate = ({ participantes }) => {
		if (!participantes) return false;

		let otroaparticipantes = 0;
		if (participantes.length > 3) {
			// console.log(participantes.length);
			otroaparticipantes = participantes.length - 3;
			// console.log({ participantes, otroaparticipantes });
		}

		return (
			<AvatarGroup className='mb-3'>
				{participantes.slice(0, 3).map((participante) => {
					return (
						<Avatar
							image={participante.fotoacreditacion}
							size='large'
							shape='circle'
							key={participante.cod_acr_dap}
						/>
					);
				})}
				{otroaparticipantes > 0 && ( // Mostrar el avatar adicional solo si hay más de 3 participantes
					<Avatar
						label={`+${otroaparticipantes}`} // Mostrar el número exacto de participantes adicionales
						shape='circle'
						size='large'
						style={{ backgroundColor: "#9c27b0", color: "#ffffff" }}
					/>
				)}
			</AvatarGroup>
		);
	};

	const tagBodyTemplate = (rowData) => {
		return (
			<Tag
				rounded
				className='9-1'
				style={{ background: `${rowData.col_tag}`, color: "white" }}
				value={`${rowData.nom_tag}`}></Tag>
		);
	};

	const LugarBodyTemplate = (rowData) => {
		return (
			<div>
				<h5>Salon principal</h5>
			</div>
		);
	};

	const ubicacionBodyTemplate = (rowData) => {
		return (
			<div>
				<h5>Mesa 10</h5>
			</div>
		);
	};

	const HoraBodyTemplate = (rowData) => {
		return (
			<div>
				<p>11 P.M.</p>
			</div>
		);
	};

	return (
		<div className='grid'>
			<div className='col-12'>
				<div className='card'>
					<Cargando cargando={cargando} />
					<Toast ref={toast} />
					<BarraSuperior openNew={openNew} />
					<TablaDatos datostabla={dataSet} titulo='Citas'>
						<Column
							field='id'
							header='Codigo'
							sortable
							headerStyle={{
								width: "10%",
								minWidth: "10rem",
							}}></Column>

						<Column
							field='nom_tag'
							header='Tipo'
							sortable
							body={tagBodyTemplate}
							headerStyle={{
								width: "10%",
								minWidth: "10rem",
							}}></Column>

						<Column
							field='fec_age'
							header='Fecha'
							sortable
							// body={LugarBodyTemplate}
							headerStyle={{
								width: "10%",
								minWidth: "10rem",
							}}></Column>

						<Column
							field='horario'
							header='Hora'
							sortable
							// body={LugarBodyTemplate}
							headerStyle={{
								width: "10%",
								minWidth: "10rem",
							}}></Column>

						<Column
							header='Lugar'
							sortable
							field='nom_lug'
							// body={LugarBodyTemplate}
							headerStyle={{
								width: "20%",
								minWidth: "10rem",
							}}></Column>
						<Column
							header='Ubicacion'
							sortable
							field='nom_ubi'
							// body={LugarBodyTemplate}
							headerStyle={{
								width: "20%",
								minWidth: "10rem",
							}}></Column>

						<Column
							field=''
							header='Participantes'
							sortable
							body={avatarBodyTemplate}
							headerStyle={{
								width: "55%",
								minWidth: "10rem",
							}}></Column>

						<Column
							header='Acciones'
							body={actionBodyTemplate}></Column>
					</TablaDatos>

					<Dialog
						visible={productDialog}
						style={{ width: "850px" }}
						header='Detalle Cita'
						modal={true}
						className='p-fluid'
						footer={productDialogFooter(hideDialog, saveProduct)}
						onHide={hideDialog}>
						<TabView>
							<TabPanel
								className='justify-content: flex-end;'
								headerTemplate={tabHeaderIIespanol}>
								<div className='formgrid grid'>
									<div className='field col-6'>
										<label htmlFor='tip_age'>Tipo:</label>
										<Dropdown
											disabled={validHora}
											value={formData.tip_age}
											onChange={(e) => {
												dispatch(
													setFormData({
														...formData,
														tip_age: e.value,
													})
												);
											}}
											options={valueDropTipoEventos}
											optionLabel='name'
											placeholder='Seleccione'
											required
											autoFocus
										/>
										{submitted && !formData.tip_age && (
											<small className='p-invalid'>
												Campo requerido.
											</small>
										)}
									</div>
									<div className='field col-6'>
										<label htmlFor='fec_age'>Fecha:</label>
										<Calendar
											dateFormat='yy-mm-dd'
											value={
												new Date(
													formData.fec_age +
														"T00:00:00"
												)
											}
											onChange={(e) =>
												onInputChangeDate(e, "fec_age")
											}
											required
											autoFocus
											className={classNames({
												"p-invalid":
													submitted &&
													!formData.fec_age,
											})}></Calendar>
										{submitted && !formData.fec_age && (
											<small className='p-invalid'>
												Campo requerido.
											</small>
										)}
									</div>
								</div>

								<div className='col formgrid grid'>
									<div className='field col-3'>
										<label htmlFor='hor_ini_age'>
											Hora Inicio:
										</label>

										<Dropdown
											value={formData.hor_ini_age}
											onChange={(e) => {
												dispatch(
													setFormData({
														...formData,
														hor_ini_age: e.value,
													})
												);
											}}
											options={valueDropHora}
											optionLabel='name'
											placeholder='Seleccione'
										/>
										{submitted && !formData.hor_ini_age && (
											<small className='p-invalid'>
												Campo requerido.
											</small>
										)}
									</div>

									<div className='field col-3'>
										<label htmlFor='hor_ave'>
											Minuto Inicio:
										</label>

										<Dropdown
											value={formData.min_ini_age}
											onChange={(e) => {
												dispatch(
													setFormData({
														...formData,
														min_ini_age: e.value,
													})
												);
											}}
											options={valueDropMinuto}
											optionLabel='name'
											placeholder='Seleccione'
										/>
										{submitted && !formData.min_ini_age && (
											<small className='p-invalid'>
												Campo requerido.
											</small>
										)}
									</div>
									<div className='field col-3'>
										<label htmlFor='hor_fin_age'>
											Hora Fin:
										</label>

										<Dropdown
											value={formData.hor_fin_age}
											onChange={(e) => {
												dispatch(
													setFormData({
														...formData,
														hor_fin_age: e.value,
													})
												);
											}}
											options={valueDropHora}
											optionLabel='name'
											placeholder='Seleccione'
										/>
										{submitted && !formData.hor_fin_age && (
											<small className='p-invalid'>
												Campo requerido.
											</small>
										)}
									</div>

									<div className='field col-3'>
										<label htmlFor='min_fin_age'>
											Minuto Fin:
										</label>

										<Dropdown
											value={formData.min_fin_age}
											onChange={(e) => {
												dispatch(
													setFormData({
														...formData,
														min_fin_age: e.value,
													})
												);
											}}
											options={valueDropMinuto}
											optionLabel='name'
											placeholder='Seleccione'
										/>
										{submitted && !formData.min_fin_age && (
											<small className='p-invalid'>
												Campo requerido.
											</small>
										)}
									</div>
								</div>

								<div className='col formgrid grid'>
									<div className='field col-6'>
										<label htmlFor='cod_lug_age'>
											Lugar:
										</label>
										<Dropdown
											disabled={validHora}
											value={formData.cod_lug_age}
											onChange={(e) => {
												dispatch(
													setFormData({
														...formData,
														cod_lug_age: e.value,
														cod_ubi_age: null,
													})
												);
											}}
											options={valueDropLugares}
											optionLabel='name'
											placeholder='Seleccione'
										/>
										{submitted && !formData.cod_lug_age && (
											<small className='p-invalid'>
												Campo requerido.
											</small>
										)}
									</div>
									<div className='field col-6'>
										<label htmlFor='cod_ubi_age'>
											Ubicacion:
										</label>
										<Dropdown
											disabled={validHora}
											value={formData.cod_ubi_age}
											onChange={(e) => {
												dispatch(
													setFormData({
														...formData,
														cod_ubi_age: e.value,
													})
												);
											}}
											options={valueDropUbicacionfilter}
											optionLabel='name'
											placeholder='Seleccione'
										/>
										{submitted && !formData.cod_ubi_age && (
											<small className='p-invalid'>
												Campo requerido.
											</small>
										)}
									</div>
								</div>

								{formData?.tip_age == 3 && (
									<div className='field col'>
										<label htmlFor='tit_age'>
											Nombre evento:
										</label>
										<InputText
											id='tit_age'
											value={formData.tit_age}
											onChange={(e) =>
												onInputChange(e, "tit_age")
											}
										/>
									</div>
								)}

								<div className='field col '>
									<label htmlFor='not_age'>Nota:</label>
									<InputText
										id='not_age'
										value={formData.not_age}
										onChange={(e) =>
											onInputChange(e, "not_age")
										}
									/>
								</div>

								<div className='field col hidden'>
									<InputText
										id='listado'
										value={formData.listado}
									/>
								</div>
								{/* {lugarDisponible && (
									<div className='card'>
										<h5>Acreditados:</h5>
										<ListBox
											value={selectedAcreditados}
											options={valueOpcionsacreditados}
											onChange={(e) =>
												setselectedAcreditados(e.value)
											}
											multiple
											filter
											optionLabel='nombre'
											itemTemplate={countryTemplate}
											style={{ width: "25rem" }}
											listStyle={{ maxHeight: "250px" }}
										/>
									</div>
								)} */}

								<div className='col formgrid grid'>
									<div className='field col-6'>
										{/* {lugarDisponible && ( */}
										<div className='card'>
											<h5>Acreditados:</h5>
											<ListBox
												value={selectedAcreditados}
												options={
													valueOpcionsacreditados
												}
												onChange={
													(e) => console.log(1)
													// setselectedAcreditados(
													// 	e.value
													// )
												}
												multiple
												filter
												optionLabel='nombre'
												itemTemplate={countryTemplate}
												style={{ width: "25rem" }}
												listStyle={{
													maxHeight: "450px",
												}}
											/>
										</div>
										{/* )} */}
									</div>
									<div className='field col-6'>
										{
											// console.log({personasAgenda})
											// console.log({valueOpcionsacreditados})
										}

										{/* {personasAgenda.length>0 && ( */}

										<div className='card'>
											<h5>Agendados:</h5>
											<ListBox
												options={cardseleccionados}
												optionLabel='nombre'
												itemTemplate={InvitadoTemplate}
												style={{ width: "25rem" }}
												listStyle={{
													maxHeight: "550px",
												}}
											/>
										</div>
										{/* )}  */}
									</div>

									{/* {JSON.stringify(formData)} */}
								</div>
							</TabPanel>

							<TabPanel headerTemplate={tabHeaderIIingles}>
								<div className='field col'>
									<label htmlFor='not_ave'>
										Nota Ingles:
									</label>
									<InputText
										id='not_ave'
										value={formData.not_ave}
										onChange={(e) =>
											onInputChange(e, "not_ave")
										}
									/>
								</div>
							</TabPanel>
						</TabView>

						{/* </div> */}
					</Dialog>
					<EliminarVentana
						deleteProductDialog={deleteProductDialog}
						product={formData.nom_tag}
						hideDeleteProductDialog={hideDeleteProductDialog}
						deleteProduct={deleteProduct}
					/>
				</div>
			</div>
		</div>
	);
};
