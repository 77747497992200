import { useState, useEffect, useRef } from "react";
import {
	Column,
	Toast,
	Button,
	AvatarGroup,
	Avatar,
	InputSwitch,
} from "../../components/crud";

import { Conexion } from "../../service/Conexion";
import { TablaDatos } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { setDataSet, setFormData } from "../../store/appSlice";
import { Cargando } from "../../components/crud/Cargando";

export const AgendaPersona = () => {
	const TABLA = "agendamientoagenda";
	let emptyFormData = {};
	const { dataSet, formData } = useSelector((state) => state.appsesion); //datos el storage redux
	const dispatch = useDispatch();

	const toast = useRef(null);
	const [recargar, setrecargar] = useState(0);
	const [cargando, setCargando] = useState(false);
	const datatable = new Conexion();
	const [taggleAgenda, settaggleAgenda] = useState(0);

	useEffect(() => {
		//cargar la data total
		setCargando(true);
		dispatch(setFormData(emptyFormData));

		datatable.gettable(TABLA).then((data) => {
			dispatch(setDataSet(data));
			setCargando(false);
			// console.log({data});
		});
		// setcardseleccionados([]);
	}, [recargar]);

	useEffect(() => {
		if (taggleAgenda != 0) {
			datatable
				.gettable("acreditadosAgendastatus/" + taggleAgenda)
				.then((datos) => {
					// console.log(datos);
					setrecargar(recargar + 1);
					// setdropdownTipoAliado(datos);
				});
		}
	}, [taggleAgenda]);

	const actionBodyTemplate = (rowData) => {
		return (
			<div
				className='actions'
				style={{
					display: "flex",
				}}>
				<Button
					icon='pi pi-sitemap'
					className='p-button-rounded p-button-success mr-2'
					onClick={() =>
						window.open(
							`https://www.bogotamarket.com/agenda1/${rowData?.usu_reg}`
						)
					}
				/>
			</div>
		);
	};

	const avatarBodyTemplate = ({ fotoacreditacion }) => {
		// console.log(fotoacreditacion);
		if (!fotoacreditacion) return false;

		return (
			<AvatarGroup className='mb-3'>
				<Avatar
					image={fotoacreditacion}
					size='large'
					shape='circle'
					key={fotoacreditacion}
				/>
			</AvatarGroup>
		);
	};
	const AgendaBodyTemplate = ({
		fotoacreditacion,
		usu_reg: idacreditado,
		activo,
	}) => {
		// console.log(idacreditado);
		if (!fotoacreditacion) return false;
		let active = activo === "1" ? true : false;
		// console.log(active);
		return (
			<InputSwitch
				checked={active}
				onChange={(e) => settaggleAgenda(idacreditado)}
			/>
		);
	};

	return (
		<div className='grid'>
			<div className='col-12'>
				<div className='card'>
					<Cargando cargando={cargando} />
					<Toast ref={toast} />
					{/* <BarraSuperior openNew={openNew} /> */}
					<TablaDatos datostabla={dataSet} titulo='Agendas'>
						<Column
							field='fotoacreditacion'
							header='Foto'
							sortable
							body={avatarBodyTemplate}
							headerStyle={{
								width: "5%",
								minWidth: "10rem",
							}}></Column>

						<Column
							field='nombre'
							header='Acreditado'
							sortable
							// body={avatarBodyTemplate}
							headerStyle={{
								width: "55%",
								minWidth: "10rem",
							}}></Column>

						<Column
							header='Activo'
							body={AgendaBodyTemplate}></Column>

						<Column
							header='Acciones'
							body={actionBodyTemplate}></Column>
					</TablaDatos>
				</div>
			</div>
		</div>
	);
};
